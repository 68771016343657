<template>
  <div>
    <div class="login mt-28">
      <a-button
        :style="{
          border: 'none',
          background: 'none',
          color: '#000',
          boxShadow: 'none',
          padding: '0px'
        }"
        class="my-6"
        @click="$router.go(-1)"
      >
        <template #icon>
          <ArrowLeftOutlined />
        </template>
        Login</a-button
      >
      <p class="my-4 text-xl font-medium">
        Enter your registered email to reset your password
      </p>
      <a-form
        layout="vertical"
        :model="credentials"
        ref="forgotRef"
        :rules="rules"
        class="my-8"
      >
        <a-form-item label="Email:" name="email">
          <a-input
            v-model:value="credentials.email"
            placeholder="ademola@gmail.com"
          />
        </a-form-item>
        <a-form-item>
          <AppButton
            buttonText="Send"
            @buttonAction="handleForgotPass"
            :loading="requestLoading"
            buttonClass="w-full"
          />
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, reactive } from 'vue'
import useLoadingState from '@/use/loading-state'
import { useStore } from 'vuex'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'
import useNotifier from '@/use/notifier'
export default {
  components: { ArrowLeftOutlined },
  setup() {
    const store = useStore()
    const { requestLoading } = useLoadingState()
    useNotifier()
    const state = reactive({
      credentials: {
        email: ''
      }
    })
    const forgotRef = ref()
    const rules = {
      email: [
        {
          required: true,
          message: 'Please enter your email address',
          trigger: 'blur'
        },
        {
          type: 'email',
          message: 'Please enter a valid email',
          trigger: 'change'
        }
      ]
    }
    const handleForgotPass = () => {
      forgotRef.value
        .validate()
        .then(() => {
          store.dispatch('auth/forgotPassword', state.credentials)
        })
        .catch((error) => {
          console.log('error during validation', error)
        })
    }
    return {
      ...toRefs(state),
      rules,
      forgotRef,
      requestLoading,
      handleForgotPass
    }
  }
}
</script>

<style lang="scss" scoped></style>
